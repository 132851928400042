<template>
  <div class="fotoWrap">
    <input type=file v-on:change="previewImage" :id="target" :capture="capture" :accept="accept">
    <button v-if="imageData.length === 0">Сделать фото</button>
    <button v-else>Изменить фото</button>
  </div>
</template>

<script>
  export default {
    name: "imageInput",
    props:['target','capture','accept'],
    data: function () {
      return {
        imageData: ''
      }
    },
    methods: {
      previewImage: function (event) {
        // Reference to the DOM input element
        var input = event.target;
        var self = this;
        // Ensure that you have a file before attempting to read it
        if (input.files && input.files[0]) {
          // create a new FileReader to read this image and convert to base64 format
          var reader = new FileReader();
          // Define a callback function to run, when FileReader finishes its job
          reader.onload = (e) => {
            // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
            // Read image as base64 and set to imageData
            this.imageData = e.target.result;
            this.$emit('imageData',{value:this.imageData,target: this.target});
            console.log(input.size)
          };
          // Start the reader job - read file as a data url (base64 format)
          reader.readAsDataURL(input.files[0]);
        }
      }
    },
  }
</script>

<style scoped>
    input{position: absolute;top:0;left:0;width: 100%;height: 100%;opacity: 0;}
    button{pointer-events: none}
    .fotoWrap{position: relative}
</style>
